import {
  CheckOutlined,
  CloseOutlined,
  EyeFilled,
  EyeInvisibleFilled,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { Input } from "antd";
import React, { useState } from "react";
import { useDisclosure } from "../../hooks/useDisclosure";
import "./_input.scss";

const CustomInput = ({
  field,
  form,
  suffix,
  isPassword,
  label,
  prefix,
  innerRef,
  type,
  ...rest
}) => {
  const { name, value, onChange, onBlur } = field;
  const error = form.errors[name];
  const touched = form.touched[name];

  const { isOpen: showPassword, toggle: toggleShowPassword } =
    useDisclosure(false);
  const { isOpen: showValidation, toggle: toggleShowValidation } =
    useDisclosure(false);

  const [validations, setValidations] = useState({
    minLength: false,
    specialChar: false,
    hasNumber: false,
    hasUppercase: false,
  });

  const validatePassword = (password) => {
    const minLength = password.length >= 8;
    const specialChar = /[!@#\$%\^&\*\?\.\(\)\-_+=~:|\/\\§±,{}[\]#<>`'"]/.test(
      password
    );
    const hasNumber = /\d/.test(password);
    const hasUppercase = /[A-Z]/.test(password);

    setValidations({
      minLength,
      specialChar,
      hasNumber,
      hasUppercase,
    });
  };

  const togglePasswordVisibility = () => {
    toggleShowPassword();
  };
  const toggleValidation = () => {
    toggleShowValidation();
  };

  const handlePasswordChange = (e) => {
    const newValue = e.target.value;
    onChange(e);
    validatePassword(newValue);
  };
  return (
    <div className="custom-input">
      <label htmlFor={name}>{label}</label>
      <div className="input-wrapper">
        <Input
          {...rest}
          ref={innerRef}
          name={name}
          value={value}
          type={showPassword ? "text" : type}
          onChange={isPassword ? handlePasswordChange : onChange}
          onBlur={onBlur}
          prefix={prefix}
          suffix={
            isPassword ? (
              <span onClick={togglePasswordVisibility}>
                {showPassword ? (
                  <EyeFilled
                    style={{
                      fontSize: "22px",
                      color: "rgb(163 163 163)",
                      padding: "30px",
                    }}
                  />
                ) : (
                  <EyeInvisibleFilled
                    style={{
                      fontSize: "22px",
                      color: "rgb(163 163 163)",
                      padding: "0 30px",
                    }}
                  />
                )}
              </span>
            ) : (
              suffix
            )
          }
        />
        {isPassword && (
          <div
            className="password-validation-toggle"
            onClick={toggleValidation}
          >
            <InfoCircleOutlined style={{ fontSize: "20px", color: "grey" }} />
          </div>
        )}
      </div>
      {touched && error && <div className="error-displayer">{error}</div>}

      {isPassword && showValidation && (
        <div className="password-validation">
          <ValidationItem
            isValid={validations.minLength}
            text="8 caractères minimum"
          />
          <ValidationItem isValid={validations.hasNumber} text="1 chiffre" />
          <ValidationItem
            isValid={validations.specialChar}
            text="1 caractère spécial"
          />
          <ValidationItem
            isValid={validations.hasUppercase}
            text="1 majuscule"
          />
        </div>
      )}
    </div>
  );
};

export default CustomInput;

const ValidationItem = ({ isValid, text }) => {
  return (
    <div className="password-validation-item">
      {isValid ? (
        <CheckOutlined style={{ color: "#22C08D" }} />
      ) : (
        <CloseOutlined style={{ color: "red", fontSize: "9px" }} />
      )}
      <p>{text}</p>
    </div>
  );
};
