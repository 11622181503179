import dayjs from "dayjs";
import { DateTime } from "luxon";
import { formatDateTimeAccordingToTimeZone } from "./formatDateAccordingToTimeZone";

dayjs.locale("fr-accented");
const customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

function defaultDate() {
  const currentDate = new Date();

  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  let startYear = currentYear;
  let startMonth = currentMonth - 1;

  if (startMonth === -1) {
    startYear = currentYear - 1;
    startMonth = 11;
  }

  const startDate = `${startYear}-${String(startMonth + 1).padStart(2, "0")}`;

  const endYear = startYear + 1;
  const endMonth = startMonth;

  const endDate = `${endYear}-${String(endMonth + 1).padStart(2, "0")}`;

  return { startDate, endDate };
}
const generateMonthList = (startDate, endDate) => {
  const start = dayjs(startDate, "YYYY-M");
  const end = dayjs(endDate, "YYYY-M");
  const months = [];
  let currentDate = start;

  while (currentDate.isBefore(end) || currentDate.isSame(end, "month")) {
    months.push(currentDate.format("MMMM YYYY"));
    currentDate = currentDate.add(1, "month");
  }
  return months;
};
const parseMonthYear = (monthYear) => {
  return dayjs(monthYear, { format: "MMMM YYYY" });
};
const parseDate = (monthYear) => {
  const parsedDate = dayjs(monthYear, "MMMM YYYY");
  return parsedDate;
};
function convertFrenchDateToFormattedDate(frenchDate) {
  dayjs.locale("fr");
  const today = dayjs().date();

  const formattedDate = dayjs(frenchDate, "MMMM YYYY")
    .date(today)
    .format("YYYY-MM-DD");
  return formattedDate;
}
function convertToYYYYMM(inputDate) {
  const dateObject = dayjs(inputDate, "MMMM YYYY");
  return dateObject.format("YYYY-MM");
}

function convertToYYYYMMDD(inputDate, defaultDay = 1) {
  let dateObject;

  if (defaultDay === "lastDay") {
    dateObject = dayjs(inputDate, "MMMM YYYY").endOf("month");
  } else {
    dateObject = dayjs(inputDate, "MMMM YYYY").date(defaultDay);
  }
  const formattedDate = formatDateTimeAccordingToTimeZone(
    dateObject,
    "yyyy-MM-dd"
  );

  return formattedDate;
}
function formatMonthYearToYearMonth(inputDate) {
  const dateObject = dayjs(inputDate, "MMMM YYYY");
  return dateObject.format("YYYY MMMM");
}
function changeMonthToFrench(dateString) {
  const monthMappings = {
    January: "janvier",
    February: "février",
    March: "mars",
    April: "avril",
    May: "mai",
    June: "juin",
    July: "juillet",
    August: "août",
    September: "septembre",
    October: "octobre",
    November: "novembre",
    December: "décembre",
  };

  const [month, year] = dateString.split(" ");

  const frenchMonth = monthMappings[month];

  if (frenchMonth) {
    return `${frenchMonth} ${year}`;
  }

  return dateString;
}
const getMonthAbbreviationFR = (month) => {
  const monthAbbreviationsFR = [
    "Jan",
    "Fév",
    "Mar",
    "Avr",
    "Mai",
    "Jui",
    "Juil",
    "Aoû",
    "Sep",
    "Oct",
    "Nov",
    "Déc",
  ];
  return monthAbbreviationsFR[month];
};
function formatMonthRange(selectedMonthRange) {
  if (selectedMonthRange && selectedMonthRange.length === 2) {
    const startMonth = selectedMonthRange[0]?.$M;
    const endMonth = selectedMonthRange[1]?.$M;
    const startYear = selectedMonthRange[0]?.$y.toString().slice(-2);
    const endYear = selectedMonthRange[1]?.$y.toString().slice(-2);

    return `${getMonthAbbreviationFR(
      startMonth
    )}.${startYear}-${getMonthAbbreviationFR(endMonth)}.${endYear}`;
  }

  return "";
}

const formatDateString = (dateString) => {
  return dateString ? dayjs(dateString).format("YYYY-MM-DD") : null;
};

export {
  convertToYYYYMMDD,
  changeMonthToFrench,
  generateMonthList,
  convertToYYYYMM,
  formatMonthYearToYearMonth,
  parseMonthYear,
  parseDate,
  convertFrenchDateToFormattedDate,
  getMonthAbbreviationFR,
  formatMonthRange,
  formatDateString,
  defaultDate,
};
