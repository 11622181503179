import React, { useState } from "react";
import CustomNavLink from "./CustomNavLink";
import CustomRangePicker from "../../../components/RangePicker";
import MenuPopover from "./MenuPopover";
import MenuButton from "./MenuButton";
import { Tooltip } from "antd";
import { useLocation } from "react-router-dom";

const NavButton = ({
  id,
  route,
  onClick,
  name,
  icon,
  isActive,
  isNavButton,
  arrowIcon,
  label,
  hasDivider,
  openOverlay,
  isSubNav,
  background,
  isSwitch,
  isDatePicker,
  handleMonthChange,
  selectedMonthRange,
  subItems,
  disabled,
  isPopover,
  mode,
}) => {
  const helpButton = name === "Besoin d’aide ?";
  const modeButton = name === "Trésorerie";
  const [helpHovered, setHelpHovered] = useState(false);
  const location = useLocation();

  return isNavButton ? (
    <CustomNavLink
      key={id}
      route={route}
      name={name}
      label={label}
      openOverlay={openOverlay}
      arrowIcon={arrowIcon}
      icon={icon}
      isActive={isActive}
      onClick={onClick}
      background={background}
      isSwitch={isSwitch}
      subItems={subItems}
      isPopover={isPopover}
      modeButton={modeButton}
    />
  ) : isPopover ? (
    <MenuPopover
      isPopover={isPopover}
      isActive={isActive}
      isSubNav={isSubNav}
      icon={icon}
      label={label}
      background={background}
      name={name}
      subItems={subItems}
    />
  ) : isSubNav ? (
    <CustomNavLink
      name={name}
      label={label}
      icon={icon}
      isActive={isActive}
      route={route}
      onClick={onClick}
      background={background}
      isSubNav={isSubNav}
      className={` button nav-link `}
      isSwitch={isSwitch}
    />
  ) : isDatePicker ? (
    <>
      <CustomRangePicker
        selectedMonthRange={selectedMonthRange}
        handleMonthChange={handleMonthChange}
        className={"button nav-link custom-range-picker"}
        hasDivider={hasDivider}
        label="Période Affichée"
        index={1}
      />
      {hasDivider && (
        <div
          className={`
          divider ${mode === "treso" ? " treso-divider" : "compta-divider"} `}
        ></div>
      )}
    </>
  ) : (
    <>
      <Tooltip
        visible={
          (helpHovered && helpButton) ||
          (helpHovered &&
            name === "Afficher la synthèse" &&
            location.pathname.includes("listing")) ||
          (helpHovered &&
            name === "Afficher un budget prev." &&
            location.pathname.includes("listing")) ||
          (helpHovered &&
            mode !== "compta" &&
            name === "Afficher un budget prev.")
        }
        trigger="hover"
        title={
          helpHovered && helpButton
            ? "En cours de construction ! N'hésitez pas à nous contacter via: tresovista@tresovista.com"
            : name === "Afficher la synthèse" &&
              location.pathname.includes("listing")
            ? `Pour afficher la synthèse, vous devez passer en vue "Catégories"`
            : (name === "Afficher un budget prev." &&
                location.pathname.includes("listing")) ||
              (mode !== "compta" && name === "Afficher un budget prev.")
            ? `Pour afficher un budget prévisionnel vous devez passer en mode "Compta"  ET en vue "Catégories"`
            : "laaaa"
        }
      >
        <MenuButton
          key={id}
          name={
            name === "Afficher la synthèse"
              ? mode === "compta"
                ? "Afficher une synthèse du résultat (compta)"
                : "Afficher une synthèse de trésorerie "
              : name
          }
          handleSubmit={(e) => {
            onClick(name);

            e.preventDefault();
          }}
          onHoveringOut={() => setHelpHovered(false)}
          onHoverIn={() => setHelpHovered(true)}
          className={
            helpButton
              ? "button nav-link config-buttons "
              : `button nav-link ${disabled ? " disabled" : ""}`
          }
          primaryIcon={icon}
          secondaryIcon={arrowIcon}
        />
      </Tooltip>
      {hasDivider && (
        <div
          className={`divider ${
            mode === "treso" ? "treso-divider" : "compta-divider"
          }`}
        ></div>
      )}
    </>
  );
};
export default NavButton;
