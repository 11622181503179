import React from "react";

const Overlay = ({ children, show, onClick, closable }) => {
  const overlayStyles = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    padding: "3.2rem 15.1rem",
    display: show ? "block" : "none",
    zIndex: 120,
    overflow: "scroll",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  };

  const handleOverlayClick = (e) => {
    // if (closable && e.target === e.currentTarget) {
    //   onClick();
    // }
  };

  return (
    <div style={overlayStyles} onClick={handleOverlayClick}>
      {children}
    </div>
  );
};

export default Overlay;
