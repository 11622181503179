import { Modal } from "antd";
import React from "react";
import Button from "../../Button";
import Title from "../../Project/Title";

function VerifyClosing({ open, handleClose, data }) {
  const handleCloseFunc = () => {
    data.onClose();
  };

  return (
    <Modal
      destroyOnClose
      open={open}
      width={"60%"}
      centred
      maskClosable={false}
      className="close-writing-modal"
      footer={false}
      closable={false}
      focusTriggerAfterClose={false}
    >
      <Title
        isDelete
        projectTitle={`Vous n'avez pas terminé votre saisie!`}
        subTitle={
          <>
            Lorsque vous saisissez directement dans les cellules du tableau,{" "}
            <br />
            vous devez valider votre saisie par la touche entrée.
          </>
        }
      />
      <div className="verify-inputs-popup">
        <Button
          className={"button-verify-inputs"}
          text={"Abandonner"}
          onClick={() => data.abandon()}
        />
        <Button
          className={"button-verify-inputs"}
          text={"Revenir à la saisie en cours"}
          onClick={() => handleCloseFunc()}
        />
      </div>
    </Modal>
  );
}

export default VerifyClosing;
