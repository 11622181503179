import React, { useCallback, useEffect, useState } from "react";
import { Modal } from "antd";
import AddWritingForm from "./AddWritingForm";
import Button from "../../Button";
import HeaderOverlay from "../../Project/Header";
import Overlay from "../../Overlay";
import Title from "../../Project/Title";

const ModalComponent = ({
  id,
  open,
  headerContent,
  handleClose,
  data,
  content,
  ...rest
}) => {
  const [showModal, setShowModal] = useState(false);
  const [lastFocusedInput, setLastFocusedInput] = useState("designation");

  const updateInputFocus = useCallback(
    () =>
      setTimeout(() => {
        const inputToFocus = document.getElementsByName(lastFocusedInput)[0];
        if (inputToFocus) {
          inputToFocus.focus();
        }
      }, 100),
    [lastFocusedInput]
  );

  useEffect(() => {
    if (lastFocusedInput && open) {
      updateInputFocus();
    }
  }, [open, lastFocusedInput]);

  useEffect(() => {
    if (!showModal) updateInputFocus();
  }, [showModal]);

  const cancelChanges = (id) => {
    handleClose(id);
    setShowModal(false);
  };

  const handleInputFocus = (inputName) => setLastFocusedInput(inputName);
  const handleModalContinue = () => setShowModal(false);

  return (
    <>
      <Modal
        destroyOnClose
        title={data?.edit ? "Modifier une écriture" : "Nouvelle écriture"}
        open={open}
        onCancel={() => {
          setShowModal(true);
        }}
        focusTriggerAfterClose={false}
        centred
        style={{ top: 30 }}
        className="add-writing-modal"
        footer={false}
        closable={false}
      >
        <AddWritingForm
          onCancel={() => handleClose(id)}
          edit={data?.edit}
          handleInputFocus={handleInputFocus}
          element={data?.element}
          selectedCategory={data?.selectedCategory}
          projectId={data?.projectId}
          isAction={data?.isAction}
          date={data?.date}
          type={data?.type}
        />
      </Modal>

      <Modal
        destroyOnClose
        open={showModal}
        width={"60%"}
        onCancel={() => handleClose(id)}
        centred
        maskClosable={false}
        className="close-writing-modal"
        footer={false}
        closable={false}
        focusTriggerAfterClose={false}
      >
        <Title
          isDelete
          projectTitle={`Vous n'avez pas terminé votre saisie!`}
          subTitle={
            <>
              Lorsque vous saisissez dans une fenêtre type formulaire, <br />
              vous devez valider la saisie par le bouton « valider ».
            </>
          }
        />
        <div className="verify-inputs-popup">
          <Button
            className={"button-verify-inputs"}
            text={"Abandonner"}
            onClick={() => cancelChanges(id)}
          ></Button>

          <Button
            className={"button-verify-inputs"}
            text={"  Revenir à la saisie en cours"}
            onClick={handleModalContinue}
          ></Button>
        </div>
      </Modal>
    </>
  );
};

export default ModalComponent;
