import { createSlice } from "@reduxjs/toolkit";
import { defaultDate, generateMonthList } from "../../utilities/filters";
const { startDate, endDate } = defaultDate();

const initialState = {
  nameSubMenu: "Catégories",
  isSidebarClosed: false,
  synthesis: false,
  objectif: false,
  mode: "treso",
  isHoverDisabled: false,
  showOverlay: false,
  currentYear: new Date().getFullYear(),
  startDate: new Date(),
  endDate: new Date(),
  endDateSynthesis: new Date(),
  startDateSynthesis: new Date(),
  selectedMonths: generateMonthList(startDate, endDate),
  selectedMonthsSynthesis: generateMonthList(startDate, endDate),
};

const slice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    handleToggleSidebar: (state, action) => {
      state.isSidebarClosed = action.payload;
    },
    handleOpenOverlay: (state, action) => {
      state.showOverlay = action.payload;
    },
    handleCloseOverlay: (state, action) => {
      state.showOverlay = action.payload;
    },
    handelStartMonth: (state, action) => {
      state.startDate = action.payload;
    },
    handelEndMonth: (state, action) => {
      state.endDate = action.payload;
    },
    handleSelectedMonths: (state) => {
      state.selectedMonths = generateMonthList(state.startDate, state.endDate);
    },
    handleSelectedMonthsSynthesis: (state) => {
      state.selectedMonthsSynthesis = generateMonthList(
        state.startDateSynthesis,
        state.endDateSynthesis
      );
    },
    handleStartMonthSynthesis: (state, action) => {
      state.startDateSynthesis = action.payload;
    },
    handleHoverStatus: (state, action) => {
      state.isHoverDisabled = action.payload;
    },
    handleEndMonthSynthesis: (state, action) => {
      state.endDateSynthesis = action.payload;
    },
    handleMode: (state, action) => {
      state.mode = action.payload;
      if (action.payload === "treso") {
        state.objectif = false;
      }
    },
    handleActiveSynthesis: (state, action) => {
      state.synthesis = action.payload;
    },
    handleActiveObjectif: (state, action) => {
      state.objectif = action.payload;
    },
    setSubMenuName: (state, action) => {
      state.nameSubMenu = action.payload;
    },
  },
  extraReducers: {},
});

export const {
  handleToggleSidebar,
  handleOpenOverlay,
  handleCloseOverlay,
  handleEndMonthSynthesis,
  handelStartMonth,
  handelEndMonth,
  handleSelectedMonths,
  handleSelectedMonthsSynthesis,
  handleStartMonthSynthesis,
  handleMode,
  handleActiveSynthesis,
  handleHoverStatus,
  handleActiveObjectif,
  setSubMenuName,
} = slice.actions;
export const reducer = slice.reducer;

export default slice;
