import React from "react";
import { NavLink } from "react-router-dom";
import SwitchMode from "./SwitchMode";

function CustomNavLink({
  route,
  isActive,
  onClick,
  name,
  label,
  icon,
  isSubNav,
  background,
  isSwitch,
  subItems,
  modeButton,
  className,
}) {
  return (
    <>
      <NavLink
        to={route}
        type="button"
        onClick={() => onClick(name)}
        style={{ backgroundColor: background }}
        className={
          className
            ? className
            : isActive
            ? `active ${modeButton ? "mode-button " : "button border-category"}`
            : ` ${
                modeButton
                  ? `mode-button ${isSubNav && "subnav-button"}`
                  : `button border-category ${isSubNav && "subnav-button"}`
              }`
        }
      >
        <img className="nav-img" src={icon} alt="nav" 
          style={{ filter: "brightness(100%) invert(1)" }}
        
        />
        <span className="hidden-element">{label}</span>

        {isSwitch && subItems?.length === 2 ? (
          <div className="button-group">
            <SwitchMode />
          </div>
        ) : (
          <div className="text-container">
            <span className="hidden-element-text">{name}</span>
          </div>
        )}
      </NavLink>
    </>
  );
}

export default CustomNavLink;
