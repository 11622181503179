import React from "react";
import "./index.scss";
import { Logo } from "../../components";

function ResponsivePage() {
  return (
    <div className="wrapper-fullpage">
      <div className="logo-desc-container">
        <Logo isLogin={true} />
        <p>
          La version mobile de l'outil Tresovista n'est pas (encore) disponible
          !
        </p>
        <p> Rendez-vous sur un ordinateur de bureau pour vous connecter !</p>
      </div>
    </div>
  );
}

export default ResponsivePage;
