import React, { useEffect, useMemo, useState } from "react";
import { TreeSelect, message } from "antd";
import { Field, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import dayjs from "dayjs";
import Button from "../../Button";
import CustomInput from "../../Input";
import { displayValidationErrors } from "../../../utilities/noticeDisplayer";
import {
  WRITING_TYPES,
  ONLY_SIMUL,
  TRESORY_STATUS,
  DUPLICATE_PERIOD_TRESO,
  DUPLICATE_PERIOD_COMPTA,
} from "../../../data/constants";
import { getCategories } from "../../../data/slices/categories";
import {
  createElements,
  editElement,
  editElementSerie,
} from "../../../data/slices/elements";
import { closeModal } from "../../../data/slices/modals";
import formattedCategories from "../../../utilities/formattedCategories";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
import { useFiltersAndDates } from "../../../hooks/useFiltersAndDate";
import { findById } from "../../../utilities/table";
import {
  convertToYYYYMMDD,
  formatDateString,
} from "../../../utilities/filters";
import { useLocation } from "react-router-dom";
import CustomDateSelect from "../../CustomDateSelect";
import { daysList } from "../../../utilities/daysList";
import CustomSelect from "../../CustomSelect";
import CustomDatePicker from "../../CustomDatePicker";
import { getFieldClasses } from "../../../utilities/getFieldClasses";
import getChangedValues from "../../../utilities/getChangedValues";
import {
  handleChangeComptaDate,
  getLabelForDateDifference,
  getLastDayLabel,
  handleChangeTresoDate,
} from "../../../utilities/duplication";

const AddWritingForm = ({
  onCancel,
  edit,
  element,
  selectedCategory,
  projectId,
  isAction,
  date,
  handleInputFocus,
  type,
}) => {
  const dispatch = useDispatch();
  const isMounted = useIsMountedRef();
  const location = useLocation();
  const [submitButtonState, setSubmitButtonState] = useState(null);
  const { constructFilters, getStartAndEndDate, getStartAndEndDateSynthesis } =
    useFiltersAndDates();

  const { footerConstructedFilters, tableConstructedFilters } =
    constructFilters();
  const { startDate, endDate } = getStartAndEndDate();
  const { startDateSynthesis, endDateSynthesis } =
    getStartAndEndDateSynthesis();

  const { mode } = useSelector((state) => state.layout);
  const { reellePopup } = useSelector((state) => state.elements);
  const { categories, categoriesForSelect } = useSelector(
    (state) => state.categories
  );

  const { expandedCategories } = useSelector((state) => state.table);

  const currentCategory = reellePopup?.category
    ? reellePopup?.category
    : reellePopup;

  const categoriesForSelectToUse = currentCategory
    ? categoriesForSelect.filter(
        (el) => currentCategory?.isSpecial === el?.isSpecial
      )
    : isAction && type === "simulation"
    ? categoriesForSelect.filter((el) => el?.isSpecial !== 1)
    : categoriesForSelect;

  const categoryId = currentCategory?.id;

  const finalCategories = formattedCategories(categoriesForSelectToUse);

  useEffect(() => {
    if (categories.length === 0) {
      dispatch(getCategories({ projectId: projectId, mode: mode }));
    }
  }, [projectId, mode]);

  const WritingSchema = Yup.object().shape({
    designation: Yup.string().required("Libelle est obligatoire"),
    amount: Yup.string().required("Montant est obligatoire"),
    categoryId: Yup.string().required("Catégorie est obligatoire"),
    type: Yup.string().required("Type est obligatoire"),
    status: Yup.string().required("Statut est obligatoire"),
  });

  const customDate = dayjs(convertToYYYYMMDD(date));
  const currentDate = dayjs();

  const sameMonthAndYear =
    customDate.month() === currentDate.month() &&
    customDate.year() === currentDate.year();

  const TRESO_MODE = mode === "treso";
  const COMPTA_MODE = mode === "compta";

  const existingDateTreso = element?.dateTreso;

  const existingDateCompta = element?.dateCompta;
  const isDuplicated = !!element?.isDuplicated;

  const [selectedDateTreso, setSelectedDateTreso] = useState(existingDateTreso);

  const [selectedDateCompta, setSelectedDateCompta] =
    useState(existingDateCompta);

  const [displayValueTreso, setDisplayValueTreso] = useState(null);
  const [displayValueCompta, setDisplayValueCompta] = useState(null);

  const optionsTreso = TRESO_MODE
    ? daysList(existingDateTreso)
    : DUPLICATE_PERIOD_COMPTA;

  const optionsCompta = TRESO_MODE
    ? DUPLICATE_PERIOD_TRESO
    : daysList(existingDateCompta);

  const statusInitialValue = useMemo(() => {
    const isSpecial = currentCategory?.isSpecial;
    return edit
      ? element?.status
      : COMPTA_MODE && isSpecial
      ? "pointé"
      : "prévisionnel";
  }, [edit, element?.status, mode, currentCategory?.isSpecial]);

  const comptaDateInitialValue =
    edit && existingDateCompta !== null
      ? formatDateString(existingDateCompta)
      : (COMPTA_MODE &&
          (date && !sameMonthAndYear
            ? formatDateString(convertToYYYYMMDD(date))
            : !isAction && formatDateString(new Date()))) ||
        null;

  const tresoDateInitialValue =
    edit && existingDateTreso !== null
      ? formatDateString(existingDateTreso)
      : (TRESO_MODE &&
          (date && !sameMonthAndYear
            ? formatDateString(convertToYYYYMMDD(date))
            : !isAction && formatDateString(new Date()))) ||
        null;

  const typeInitialValue =
    type === "simulation" ? "simulation" : edit ? element.type : "réel";

  const initialValues = {
    isSpecial: currentCategory?.isSpecial || false,
    designation: edit ? element?.designation : null,
    amount: edit ? element?.amount.toString() : null,
    categoryId: currentCategory ? currentCategory?.id : null,
    dateCompta: comptaDateInitialValue,
    dateTreso: tresoDateInitialValue,
    type: typeInitialValue,
    status: statusInitialValue,
  };

  const handleSubmitButtonState = (isSubmit) => {
    setSubmitButtonState(isSubmit);
  };

  const handelChangeCategories = (setFieldValue, value) => {
    setFieldValue("categoryId", value);
    const categoryToSend = findById(value, categoriesForSelect);
    const isSpecial = categoryToSend.isSpecial;
    setFieldValue("isSpecial", isSpecial);
  };

  const onSubmit = async (values, { setSubmitting, resetForm, errors }) => {
    try {
      if (edit) {
        const amount = values?.amount;
        let dataState = {
          designation: values.designation,
          amount: amount.toString(),
          dateCompta: values?.dateCompta,
          dateTreso: values?.dateTreso,
          type: values.type,
          status: values.status,
          ...(!isDuplicated ? { categoryId: Number(values.categoryId) } : {}),
        };

        if (selectedCategory?.id !== values.category && !isDuplicated) {
          dataState = {
            designation: values.designation,
            categoryId: Number(values.categoryId),
            amount: amount.toString(),
            dateCompta: values?.dateCompta,
            dateTreso: values?.dateTreso,
            type: values.type,
            status: values.status,
          };
        }
        const editBody = {
          elementId: element?.id,
          values: dataState,
          project: {
            mode,
            projectId: projectId,
            params: { startDate, endDate, ...tableConstructedFilters },
          },
          isListing: location.pathname.includes("listing"),
          listing: {
            params: { ...tableConstructedFilters, startDate, endDate },
            mode,
            projectId,
          },
          sum: {
            mode,
            projectId: projectId,
            params: { startDate, endDate, ...footerConstructedFilters },
          },
          synthesis: {
            mode,
            projectId,
            params: {
              startDate: startDateSynthesis,
              endDate: endDateSynthesis,
              ...tableConstructedFilters,
            },
          },
        };

        const fieldUpdated = getChangedValues(editBody.values, initialValues);

        if (isDuplicated && Object.keys(fieldUpdated).length === 0) {
          message.error("Rien à éte modifié !");
        } else {
          if (isDuplicated) {
            let result;
            if (type === "all") {
              result = await dispatch(
                editElementSerie({
                  ...editBody,
                  fieldUpdated,
                  type: "all",
                })
              );
            } else {
              result = await dispatch(
                editElementSerie({
                  ...editBody,
                  fieldUpdated,
                  type: "one",
                })
              );
            }

            if (editElementSerie.rejected.match(result)) {
              if (isMounted.current) {
                setSubmitting(false);
              }
              displayValidationErrors(
                result?.errors ? result.errors : result?.payload
              );
            }
            if (editElementSerie.fulfilled.match(result)) {
              message.success(
                `${
                  type === "all" ? "La série " : "L'écriture"
                } à été modifiée avec succès`
              );

              if (submitButtonState === "validate") {
                dispatch(closeModal("add-writing-expression"));
              }
            }
          }
        }
        if (!isDuplicated) {
          const result = await dispatch(editElement({ ...editBody }));
          setSubmitting(false);
          resetForm();

          if (editElement.rejected.match(result)) {
            if (isMounted.current) {
              setSubmitting(false);
            }
            displayValidationErrors(
              result?.errors ? result.errors : result?.payload
            );
          }
          if (editElement.fulfilled.match(result)) {
            message.success("Écriture à été modifiée avec succès");

            if (submitButtonState === "validate") {
              dispatch(closeModal("add-writing-expression"));
            }
          }
        }
      } else {
        const categoryToSend = findById(values.categoryId, categoriesForSelect);
        const datesToSend =
          categoryToSend.isSpecial === 0
            ? {
                dateCompta: values.dateCompta,
                dateTreso: values?.dateTreso,
              }
            : COMPTA_MODE
            ? { dateCompta: values.dateCompta, dateTreso: null }
            : { dateTreso: values?.dateTreso, dateCompta: null };

        const result = await dispatch(
          createElements({
            categoryId: values.categoryId ? values.categoryId : categoryId,
            values: {
              ...values,
              designation: values.designation,
              amount: values.amount,
              ...datesToSend,
              type: values.type,
              status: values.status,
            },
            expandedCategories,
            project: {
              mode,
              projectId: projectId,
              params: { startDate, endDate, ...tableConstructedFilters },
            },
            isListing: location.pathname.includes("listing"),
            listing: {
              params: { ...tableConstructedFilters, startDate, endDate },
              mode,
              projectId,
            },
            sum: {
              mode,
              projectId: projectId,
              params: { startDate, endDate, ...footerConstructedFilters },
            },
            synthesis: {
              mode,
              projectId: projectId,
              params: {
                startDate: startDateSynthesis,
                endDate: endDateSynthesis,
                ...tableConstructedFilters,
              },
            },
          })
        );
        setSubmitting(false);
        if (createElements.rejected.match(result)) {
          if (isMounted.current) {
            setSubmitting(false);
          }
          displayValidationErrors(
            result?.errors ? result.errors : result.payload
          );
        }
        if (createElements.fulfilled.match(result)) {
          message.success("Écriture à été ajoutée avec succès");
          if (submitButtonState === "validate") {
            dispatch(closeModal("add-writing-expression"));
          }
          resetForm();
        }
      }
    } catch (error) {
      setSubmitting(false);
      displayValidationErrors(error);
    }
  };

  return (
    <>
      <p className="description">* champs obligatoires.</p>
      <Formik
        initialValues={initialValues}
        validationSchema={WritingSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({
          values,
          isSubmitting,
          handleChange,
          handleBlur,
          setFieldValue,
          errors,
          touched,
        }) => {
          const specialCategory = values.isSpecial;
          const statusesArray =
            values.type === "simulation" ? ONLY_SIMUL : TRESORY_STATUS;

          const labelDifference = getLabelForDateDifference(
            "compta",
            values.dateTreso,
            values.dateCompta
          );
          const labelDifferenceCompta = getLabelForDateDifference(
            "treso",
            values.dateTreso,
            values.dateCompta
          );

          const matchingLabelTreso = labelDifference["matchingLabel"];
          const monthDifferenceTreso = labelDifference["monthDifference"];
          const matchingLabelCompta = labelDifferenceCompta["matchingLabel"];
          const monthDifferenceCompta =
            labelDifferenceCompta["monthDifference"];

          return (
            <Form
              className="form-container"
              autoComplete="off"
              preserve={false}
            >
              <Field
                component={CustomInput}
                value={values.designation}
                label="Libellé*"
                name="designation"
                type="text"
                className={getFieldClasses(values.designation)}
                placeholder="Mon projet beta"
                onBlur={handleBlur}
                onChange={handleChange}
                onFocus={() => handleInputFocus("designation")}
              />
              <Field
                component={CustomInput}
                value={values.amount}
                label="Montant*"
                name="amount"
                type="string"
                className={getFieldClasses(values.amount)}
                placeholder="Mon projet beta"
                onBlur={handleBlur}
                onChange={handleChange}
                onFocus={() => handleInputFocus("amount")}
              />

              <div className="wrapper-select">
                <label htmlFor="type" className="input-text">
                  Catégorie*
                </label>
                <TreeSelect
                  showSearch
                  disabled={isDuplicated}
                  bordered={false}
                  filterTreeNode={(input, treeNode) =>
                    treeNode.title.toLowerCase().includes(input.toLowerCase())
                  }
                  name="category"
                  value={values.categoryId}
                  dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                  placeholder="Choisir une catégorie"
                  allowClear
                  treeDefaultExpandAll
                  className={getFieldClasses(values.categoryId, true)}
                  onBlur={handleBlur}
                  onChange={(value) => {
                    handelChangeCategories(setFieldValue, value);
                  }}
                  onFocus={() => handleInputFocus("category")}
                  treeData={finalCategories}
                />
              </div>
              <div className="element-wrapper">
                {isDuplicated && type == "all" ? (
                  <CustomDateSelect
                    displayValue={
                      displayValueTreso !== null
                        ? displayValueTreso
                        : TRESO_MODE
                        ? getLastDayLabel(values?.dateTreso)
                        : matchingLabelTreso
                    }
                    name="dateTreso"
                    value={values?.dateTreso}
                    options={optionsTreso}
                    specialCategory={specialCategory}
                    errors={errors}
                    touched={touched}
                    onChange={(changedValue) => {
                      handleChangeTresoDate(
                        changedValue,
                        setFieldValue,
                        setDisplayValueTreso,
                        TRESO_MODE,
                        COMPTA_MODE,
                        selectedDateTreso,
                        setSelectedDateTreso,
                        monthDifferenceCompta,
                        setSelectedDateCompta,
                        selectedDateCompta,
                        specialCategory,
                        edit,
                        displayValueCompta
                      );
                    }}
                    onBlur={handleBlur}
                    onFocus={() => handleInputFocus("dateTreso")}
                    label={
                      TRESO_MODE
                        ? "Jour du mois de trésorerie*"
                        : "Date de trésorerie (décalage)*"
                    }
                    condition={TRESO_MODE}
                  />
                ) : (
                  ((TRESO_MODE && specialCategory) || !specialCategory) && (
                    <CustomDatePicker
                      style={{ width: "100%" }}
                      label="Date de trésorerie*"
                      name="dateTreso"
                      values={values?.dateTreso}
                      onFocus={() => handleInputFocus("dateTreso")}
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      dropdownClassName="custom-datepicker-dropdown"
                    />
                  )
                )}
                {isDuplicated && type == "all" ? (
                  <CustomDateSelect
                    name="dateCompta"
                    displayValue={
                      displayValueCompta !== null
                        ? displayValueCompta
                        : TRESO_MODE
                        ? matchingLabelCompta
                        : getLastDayLabel(values?.dateCompta)
                    }
                    value={values?.dateCompta}
                    specialCategory={specialCategory}
                    options={optionsCompta}
                    errors={errors}
                    touched={touched}
                    onChange={(changedValue) =>
                      handleChangeComptaDate(
                        changedValue,
                        setFieldValue,
                        monthDifferenceTreso,
                        setDisplayValueCompta,
                        TRESO_MODE,
                        COMPTA_MODE,
                        selectedDateTreso,
                        setSelectedDateCompta,
                        selectedDateCompta,
                        setSelectedDateTreso,
                        specialCategory,
                        edit,
                        displayValueTreso
                      )
                    }
                    onBlur={handleBlur}
                    onFocus={() => handleInputFocus("dateCompta")}
                    label={
                      COMPTA_MODE
                        ? "Jour du mois de comptabilité*"
                        : "Date de comptabilité (décalage)*"
                    }
                    condition={COMPTA_MODE}
                  />
                ) : (
                  ((COMPTA_MODE && specialCategory) || !specialCategory) && (
                    <>
                      <CustomDatePicker
                        label="Date de comptabilité* "
                        name="dateCompta"
                        values={values.dateCompta}
                        onFocus={() => handleInputFocus("dateCompta")}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                      />
                    </>
                  )
                )}
              </div>
              <div className="element-wrapper">
                <CustomSelect
                  label="Statut de trésorerie*"
                  name="status"
                  errors={errors}
                  touched={touched}
                  disabledOption={isDuplicated && type == "all" ? "pointé" : ""}
                  value={
                    values.type === "simulation" ? "simulation" : values?.status
                  }
                  disabled={
                    type === "simulation" ||
                    (COMPTA_MODE && specialCategory) ||
                    (TRESO_MODE && values.type === "simulation")
                  }
                  onChange={setFieldValue}
                  options={statusesArray}
                  condition={
                    (TRESO_MODE && specialCategory) || !specialCategory
                  }
                  showIcon
                />
                <CustomSelect
                  errors={errors}
                  touched={touched}
                  label="Type d'écriture*"
                  name="type"
                  value={values?.type}
                  disabled={!edit && isAction}
                  onChange={(_, value) => {
                    setFieldValue("type", value);
                    if (value === "simulation") {
                      setFieldValue("status", "prévisionnel");
                    }
                  }}
                  options={WRITING_TYPES}
                  disabledOption="soldé"
                  condition={
                    (COMPTA_MODE && specialCategory) || !specialCategory
                  }
                  showIcon
                />
              </div>
              <div className="submit-button-wrapper">
                <Button
                  text={isSubmitting ? "envoi en cours" : "Valider"}
                  type="submit"
                  disabled={isSubmitting}
                  color="primary"
                  className="submit-button"
                  allWidth
                  onClick={() => handleSubmitButtonState("validate")}
                />
                <Button
                  text="Annuler"
                  color="primary"
                  className="reset-button"
                  allWidth
                  onClick={(e) => {
                    onCancel();
                    e.preventDefault();
                  }}
                />
              </div>
              <div className="validate">
                {!edit && (
                  <Button
                    type="submit"
                    text={
                      isSubmitting
                        ? "envoi en cours"
                        : "Valider et ajouter une nouvelle écriture"
                    }
                    disabled={isSubmitting}
                    color="primary"
                    className="validate-button"
                    allWidth
                    onClick={() => handleSubmitButtonState("submit")}
                  />
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default AddWritingForm;
